.light{
  --text-color: var(--blue);
  --bg-color: var(--beige);
}

.dark{
  --text-color: var(--white);
  --bg-color: var(--black);
}

.wrapper{
  background: var(--bg-color);
  padding: 0 !important;
}

.container {
  --size-img: 600px;
  position: relative;
  min-height: var(--size-img);
}

.container img{
  padding: 0 !important;
  grid-column: auto !important;
}

@media (max-width: 1024px) {
  .container{
    --size-img: 440px;
  }
}